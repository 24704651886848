class RequestQueue {
  constructor() {
    this.queue = [];
    this.isProcessing = false;
  }

  // Додаємо запит до черги разом із колбеками та кнопкою
  addRequest(request, onSuccess, onError, loadingBtn) {
    this.queue.push({ request, onSuccess, onError, loadingBtn });
    if (!this.isProcessing) {
      this.processQueue();
    }
  }

  async processQueue() {
    this.isProcessing = true;
    while (this.queue.length > 0) {
      const { request, onSuccess, onError, loadingBtn } = this.queue.shift();

      try {
        const result = await request();
        if (onSuccess && result.success) {
          onSuccess(result.data);
        } else {
          onError(result.data);
        }
      } catch (error) {
        if (onError) {
          onError(error);
        }
      } finally {
        // Знімаємо стан "завантаження" з кнопки
        if (loadingBtn) {
          loadingBtn.disabled = false;
          loadingBtn.classList.remove('loading');
        }

        // Reset turnstile if applicable
        if (
          document.getElementById('cf-turnstile') &&
          turnstile &&
          turnstile.reset
        ) {
          turnstile.reset('#cf-turnstile');
        }
      }
    }
    this.isProcessing = false;
  }
}

export default RequestQueue;
